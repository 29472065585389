<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.35458 7.09961C2.52871 4.39246 4.78019 2.25 7.53125 2.25C10.2272 2.25 12.4433 4.30743 12.695 6.93751C13.4813 6.93971 14.2984 7.28936 14.9175 7.85822C15.5446 8.43439 16 9.26508 16 10.25C16 10.8121 15.8708 11.6294 15.3805 12.3206C14.8698 13.0404 14.0093 13.5625 12.6875 13.5625H3.3125C1.81163 13.5625 0 12.4663 0 10.25C0 8.51299 1.22094 7.47544 2.35458 7.09961ZM3.34384 7.41042C3.34408 7.41241 3.34432 7.41446 3.34456 7.41653C3.34637 7.43264 3.34844 7.45618 3.34844 7.48438C3.34844 7.72249 3.18051 7.92758 2.94707 7.97455C2.02792 8.15951 1 8.93232 1 10.25C1 11.7837 2.224 12.5625 3.3125 12.5625H12.6875C13.7094 12.5625 14.2552 12.1784 14.5649 11.7419C14.8948 11.2769 15 10.6879 15 10.25C15 9.58867 14.6965 9.01311 14.241 8.59459C13.7795 8.17056 13.1936 7.9375 12.6875 7.9375C12.5601 7.9375 12.4349 7.95076 12.3133 7.97537C12.1663 8.00514 12.0136 7.96732 11.8975 7.87236C11.7814 7.77739 11.7141 7.63532 11.7141 7.48531C11.7141 7.45808 11.716 7.43514 11.7177 7.41874C11.718 7.416 11.7184 7.41332 11.7187 7.41075C11.7043 5.11076 9.83462 3.25 7.53125 3.25C5.22799 3.25 3.35841 5.11058 3.34384 7.41042Z"
      fill="currentColor"
    />
  </svg>
</template>
