<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3536 3.14645C10.1583 2.95118 9.84171 2.95118 9.64645 3.14645C9.45118 3.34171 9.45118 3.65829 9.64645 3.85355L12.7929 7H6C3.51472 7 1.5 9.01472 1.5 11.5V12C1.5 12.2761 1.72386 12.5 2 12.5C2.27614 12.5 2.5 12.2761 2.5 12V11.5C2.5 9.567 4.067 8 6 8H12.7929L9.64645 11.1464C9.45118 11.3417 9.45118 11.6583 9.64645 11.8536C9.84171 12.0488 10.1583 12.0488 10.3536 11.8536L14.3536 7.85355C14.5488 7.65829 14.5488 7.34171 14.3536 7.14645L10.3536 3.14645Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Share",
}
</script>
