<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.625 4.48975C9.625 5.38721 8.89746 6.11475 8 6.11475C7.10254 6.11475 6.375 5.38721 6.375 4.48975C6.375 3.59228 7.10254 2.86475 8 2.86475C8.89746 2.86475 9.625 3.59228 9.625 4.48975ZM10.625 4.48975C10.625 5.93949 9.44975 7.11475 8 7.11475C6.55025 7.11475 5.375 5.93949 5.375 4.48975C5.375 3.04 6.55025 1.86475 8 1.86475C9.44975 1.86475 10.625 3.04 10.625 4.48975ZM3.33074 11.4858C3.67105 9.95433 5.02935 8.86475 6.59812 8.86475H9.40262C10.9714 8.86475 12.3297 9.95433 12.67 11.4858L12.7627 11.903C12.9603 12.7921 12.2838 13.6354 11.3731 13.6354H4.62765C3.71694 13.6354 3.04045 12.7921 3.23801 11.903L3.33074 11.4858ZM6.59812 7.86475C4.56065 7.86475 2.79654 9.27986 2.35455 11.2688L2.26182 11.6861C1.92548 13.1996 3.0772 14.6354 4.62765 14.6354H11.3731C12.9235 14.6354 14.0753 13.1996 13.7389 11.6861L13.6462 11.2688C13.2042 9.27986 11.4401 7.86475 9.40262 7.86475H6.59812Z"
      fill="currentColor"
    />
  </svg>
</template>
