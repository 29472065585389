<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5_7864)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99976 1.85303C4.60474 1.85303 1.85254 4.60523 1.85254 8.00024C1.85254 9.57171 2.44221 11.0055 3.41235 12.0923C3.8439 11.3202 4.46904 10.6711 5.22794 10.2088C6.0402 9.71398 6.97416 9.45222 7.92649 9.45222C8.87881 9.45222 9.81277 9.71397 10.625 10.2088C11.4167 10.6911 12.0628 11.3765 12.4954 12.1928C13.52 11.0947 14.147 9.62067 14.147 8.00024C14.147 7.7241 14.3708 7.50024 14.647 7.50024C14.9231 7.50024 15.147 7.7241 15.147 8.00024C15.147 11.9475 11.9471 15.1475 7.99976 15.1475C4.05246 15.1475 0.852539 11.9475 0.852539 8.00024C0.852539 4.05295 4.05246 0.853027 7.99976 0.853027C8.2759 0.853027 8.49976 1.07688 8.49976 1.35303C8.49976 1.62917 8.2759 1.85303 7.99976 1.85303ZM11.7585 12.8648C11.7104 12.8236 11.6693 12.7726 11.6387 12.7127C11.2907 12.0331 10.7599 11.462 10.1048 11.0628C9.44955 10.6636 8.69563 10.4522 7.92649 10.4522C7.15735 10.4522 6.40343 10.6637 5.74821 11.0628C5.09303 11.462 4.56227 12.0331 4.21428 12.7128C4.19823 12.7441 4.17931 12.7731 4.15802 12.7994C5.21038 13.6429 6.54615 14.1475 7.99976 14.1475C9.41542 14.1475 10.7193 13.6689 11.7585 12.8648ZM7.92628 4.56982C6.94642 4.56982 6.1578 5.35798 6.1578 6.32321C6.1578 7.28843 6.94642 8.07659 7.92628 8.07659C8.90615 8.07659 9.69477 7.28843 9.69477 6.32321C9.69477 5.35798 8.90615 4.56982 7.92628 4.56982ZM5.1578 6.32321C5.1578 4.79941 6.40044 3.56982 7.92628 3.56982C9.45212 3.56982 10.6948 4.79941 10.6948 6.32321C10.6948 7.847 9.45212 9.07659 7.92628 9.07659C6.40044 9.07659 5.1578 7.847 5.1578 6.32321ZM12.8012 1.0127C13.0773 1.0127 13.3012 1.23655 13.3012 1.5127V2.70264H14.5027C14.7789 2.70264 15.0027 2.92649 15.0027 3.20264C15.0027 3.47878 14.7789 3.70264 14.5027 3.70264H13.3012V4.89277C13.3012 5.16891 13.0773 5.39277 12.8012 5.39277C12.525 5.39277 12.3012 5.16891 12.3012 4.89277V3.70264H11.1C10.8238 3.70264 10.6 3.47878 10.6 3.20264C10.6 2.92649 10.8238 2.70264 11.1 2.70264H12.3012V1.5127C12.3012 1.23655 12.525 1.0127 12.8012 1.0127Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_7864">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
